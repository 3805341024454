import React from 'react';
import { motion } from 'framer-motion';
import {AppWrap} from '../../wrapper';
import { images } from '../../constants';
import NaushadCV from '../../assets/Naushad-CV.pdf'
import './Header.scss';

//scaleVariants is an object
const scaleVariants = {
  whileInView: {
    scale: [0,1],
    opacity: [0,1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className='p-text'>Hi there, I am</p>
              <h1 className='head-text'>Naushad</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Web Developer</p>
            <p className='p-text'>Freelancer</p>
          </div>
          <div className='tag-cmp app__flex'>
            <a href={NaushadCV} download>Download CV</a>
          </div>
        </div>
      </motion.div>
        
      <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className='app__header-img'
      >
        {/* This is the portfolio picture */}
        <img src={images.profile} alt="profile_bg" />
        <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt='profile_circle'
        className='overlay_circle'
        />
      </motion.div>

      <motion.div
      variant={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className='app__header-circles'
      >
        {/* Here map() is used to render the different images of work */}
        {[images.react, images.redux, images.sass].map((circle,index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='circle' />
          </div>
        ))}
      
      </motion.div>


    </div>
  )
}

//AppWrap is used here for using the Appwrap for the whole header component and here home is the id we are passing
export default AppWrap(Header, 'home');